import { string } from "yup"
import { CRColumnHeader, CRColumnType } from "../app/types"
import { FilterCardValues, RangeState } from "../components/filtercard"

export function toVerboseType(header: CRColumnHeader) {
    if (header.fixedSet && !header.providerCatDependent) {
        return "Fixed selection"
    } else if (header.fixedSet) {
        return "Fixed selection"
    }

    switch (header.type) {
        case CRColumnType.Boolean:
            return "Boolean"
        case CRColumnType.String:
            return "String"
        case CRColumnType.DateTime:
            return "Date"
        case CRColumnType.Float:
            return "Float"
        case CRColumnType.Int:
            return "Integer"
        case CRColumnType.TimeDelta:
            return "Time difference"
    }
}

export function renderValues(header: CRColumnHeader, values: FilterCardValues) {
    if (header.fixedSet && !header.providerCatDependent) {
        return (values as string[]).join(", ")
    } else if (header.fixedSet) {
        const typedValue = values as Record<string, string[]>
        return Object.values(typedValue)
            .flatMap((items) => items)
            .join(", ")
    }

    switch (header.type) {
        case CRColumnType.Boolean:
            return String(values)
        case CRColumnType.String:
            return values
        case CRColumnType.DateTime:
        case CRColumnType.TimeDelta: {
            const typedValue = values as RangeState<string>
            const result: string[] = []
            if (typedValue.greater_than) {
                result.push(`> ${typedValue.greater_than}`)
            }
            if (typedValue.lesser_than) {
                result.push(`< ${typedValue.lesser_than}`)
            }
            return result.join("; ")
        }
        case CRColumnType.Float: {
            const typedValue = values as RangeState<number>
            const result: string[] = []
            if (typedValue.greater_than) {
                result.push(`> ${typedValue.greater_than.toFixed(2)}`)
            }
            if (typedValue.lesser_than) {
                result.push(`< ${typedValue.lesser_than.toFixed(2)}`)
            }
            return result.join("; ")
        }
        case CRColumnType.Int: {
            const typedValue = values as RangeState<number>
            const result: string[] = []
            if (typedValue.greater_than) {
                result.push(`> ${typedValue.greater_than.toFixed()}`)
            }
            if (typedValue.lesser_than) {
                result.push(`< ${typedValue.lesser_than.toFixed()}`)
            }
            return result.join("; ")
        }
    }
}
