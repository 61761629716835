import { PaletteMode } from "@mui/material"

declare module "@mui/material/styles/createPalette" {
    interface Palette {
        backgrounds: Palette["primary"]
    }

    interface PaletteOptions {
        backgrounds: PaletteOptions["primary"]
    }
}

export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === "light"
            ? {
                  backgrounds: {
                      main: "#F4F4F4",
                  },
              }
            : {
                  backgrounds: {
                      main: "#2D2D2D",
                  },
              }),
    },
})
