import {
    createReducer,
    miniSerializeError,
    SerializedError,
} from "@reduxjs/toolkit"
import { hasError, clearError } from "../actions"

const error = createReducer<SerializedError>(null, (builder) =>
    builder
        .addCase(hasError, (_state, action) => {
            return miniSerializeError(action.payload)
        })
        .addCase(clearError, () => {
            return null
        })
)
export default error
