import NumberFormat from "react-number-format"
import * as React from "react"

interface NumberFormatProps {
    onChange: (event: { target: { name: string; value: string } }) => void
    name: string
}

export const IntFormatedTextField = React.forwardRef<
    NumberFormat<number>,
    NumberFormatProps
>(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
            thousandSeparator
            isNumericString
            fixedDecimalScale
        />
    )
})

export const FloatFormatedTextField = React.forwardRef<
    NumberFormat<number>,
    NumberFormatProps
>(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props

    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
            thousandSeparator
            decimalScale={2}
            isNumericString
            fixedDecimalScale
        />
    )
})
