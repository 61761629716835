import * as React from "react"
import { connect, ConnectedProps } from "react-redux"

import Table from "@mui/material/Table"
import TableContainer from "@mui/material/TableContainer"
import Paper from "@mui/material/Paper"

import { fetchCustomerData, fetchHeaders, setPageTitle } from "../actions"
import { RootState } from "../app/store"
import { useAppDispatch } from "../app/hook"
import FilterBar, { FilteredColumn } from "../components/filterbar"
import OPTableHeader from "../components/optableheader"
import OPTableBody from "../components/opttablebody"
import Box from "@mui/material/Box"
import PaginationBar from "../components/paginationbar"
import LoadingTag from "../components/loadingTag"
import Stack from "@mui/material/Stack"
import {
    CRColumnHeader,
    CRColumnType,
    CRDataBodyBaseTypes,
    CRSidePanelColumnHeader,
} from "../app/types"
import SidePanel from "../components/sidepanel"

function CustomerResearch(props: ConnectedProps<typeof connector>) {
    const { customerData } = props
    const dispatch = useAppDispatch()

    const [filters, setFilters] = React.useState<FilteredColumn[]>([])
    const [sidebarItem, setSidebarItem] =
        React.useState<{
            header: CRColumnHeader
            value: Record<string, CRDataBodyBaseTypes>[]
        }>(null)

    React.useEffect(() => {
        dispatch(setPageTitle("Customer Research"))
        return () => {
            dispatch(setPageTitle(""))
        }
    })

    React.useEffect(() => {
        if (!customerData.headers && !customerData.requestingHeaders) {
            dispatch(fetchHeaders())
        }
    }, [customerData.requestingHeaders, customerData.headers])

    React.useEffect(() => {
        if (!customerData.data) {
            dispatch(fetchCustomerData())
        }
    }, [customerData.data])

    // Only non-sidebar type columns can be filtered right now
    // This is an open todo to make the sub headers filterable
    const flatHeaders =
        customerData.headers
            ?.flatMap((headers) => headers)
            .filter((header) => header.type != CRColumnType.Sidebar) || []

    const onFilterChange = (filters: FilteredColumn[]) => {
        setFilters(filters)
        dispatch(fetchCustomerData(filters))
    }

    const onPaginationChange = (nextIndex: number, pageSize: number) => {
        dispatch(fetchCustomerData(filters, nextIndex, pageSize))
    }

    const onSidebarChange = (
        header: CRColumnHeader,
        value: Record<string, CRDataBodyBaseTypes>[]
    ) => {
        setSidebarItem({
            header,
            value,
        })
    }

    const handleSidebarClose = () => {
        setSidebarItem(null)
    }

    const isLoading =
        customerData.requestingData || customerData.requestingHeaders

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 3,
                }}
            >
                <FilterBar
                    headers={flatHeaders}
                    version={customerData.headerVersion}
                    onFilterChange={onFilterChange}
                    disabled={isLoading}
                />
                <LoadingTag loading={isLoading} />
            </Box>
            <Stack
                direction="row"
                sx={{ display: "flex", flex: "1 1 auto", minHeight: 0 }}
            >
                <TableContainer
                    component={Paper}
                    sx={{
                        overflow: "scroll",
                        borderRadius: "15px",
                        alignItems: "flex-start",
                    }}
                >
                    <Table
                        stickyHeader
                        sx={{ minWidth: 650, tableLayout: "strict" }}
                        size="small"
                    >
                        <OPTableHeader headers={customerData.headers} />
                        <OPTableBody
                            headers={customerData.headers}
                            data={customerData.data}
                            pageIndex={customerData.page.index}
                            pageSize={customerData.page.size}
                            onSidebarChange={onSidebarChange}
                        />
                    </Table>
                </TableContainer>
                <SidePanel
                    open={sidebarItem != null}
                    // Because there is no typeguarding at runtime
                    header={sidebarItem?.header as CRSidePanelColumnHeader}
                    values={sidebarItem?.value}
                    onClose={handleSidebarClose}
                />
            </Stack>
            <PaginationBar
                sx={{
                    display: "flex",
                    flex: "0 0 100px",
                }}
                total={customerData.page.total}
                pageIndex={customerData.page.index}
                pageSize={customerData.page.size}
                onChange={onPaginationChange}
                disableAll={isLoading}
            />
        </Box>
    )
}

function mapStateToProps(state: RootState) {
    const { customerData } = state
    return { customerData }
}

const connector = connect(mapStateToProps)
export default connector(CustomerResearch)
