import { CRColumnHeader, CRColumnType } from "../../app/types"
import { FilteredColumn } from "../filterbar"
import BooleanFilterCard from "./boolean"
import { FilterCardButtonProps } from "./components"
import DateTimeFilterCard from "./datetime"
import DepFixedSetFilterCard from "./dependentFixedSet"
import FixedSetFilterCard from "./fixedset"
import FloatFilterCard from "./float"
import IntFilterCard from "./integer"
import StringFilterCard from "./string"
import TimeDeltaFilterCard from "./timedelta"

export interface RangeState<T> {
    greater_than: T
    lesser_than: T
}

export type FilterCardValues =
    | string[]
    | Record<string, string[]>
    | RangeState<string>
    | RangeState<number>
    | boolean

export interface FilterCardProps extends FilterCardButtonProps {
    header: CRColumnHeader
    filteredCols?: FilteredColumn[]
    onChange?: (header: CRColumnHeader, value: FilterCardValues) => void
}

function FilterCard(props: FilterCardProps) {
    const { header } = props

    if (header.fixedSet && !header.providerCatDependent) {
        return <FixedSetFilterCard {...props} />
    } else if (header.fixedSet) {
        return <DepFixedSetFilterCard {...props} />
    }

    switch (header.type) {
        case CRColumnType.Boolean:
            return <BooleanFilterCard {...props} />
        case CRColumnType.String:
            return <StringFilterCard {...props} />
        case CRColumnType.DateTime:
            return <DateTimeFilterCard {...props} />
        case CRColumnType.Float:
            return <FloatFilterCard {...props} />
        case CRColumnType.Int:
            return <IntFilterCard {...props} />
        case CRColumnType.TimeDelta:
            return <TimeDeltaFilterCard {...props} />
    }
}

export default FilterCard
