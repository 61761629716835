import Link from "@mui/material/Link"
import { FormikProps } from "formik"
import React from "react"

/**
  Helper method to return the error message only if the field is touched
*/
export function hasFormError<V>(f: FormikProps<V>, name: keyof V): string {
    const msg = f.errors[name] && f.touched[name] ? f.errors[name] : null
    return typeof msg === "string" ? msg : ""
}

const Link_Regex =
    /(([a-z]+:\/\/)?(([a-z0-9-]+\.)+([a-z]{2}|aero|arpa|biz|com|coop|edu|gov|info|int|jobs|mil|museum|name|nato|net|org|pro|travel|local|internal))(:[0-9]{1,5})?(\/[a-z0-9_\-.~]+)*(\/([a-z0-9_\-.]*)(\?[a-z0-9+_\-.%=&amp;]*)?)?(#[a-zA-Z0-9!$&'()*+.=-_~:@/?]*)?)(\s+|$)/gi

export function linkify(input: string): string | React.ReactElement {
    const searchPos = input.search(Link_Regex)
    if (searchPos >= 0) {
        const before = input.substring(0, searchPos)
        const match = input.substring(searchPos, searchPos + input.length)
        const after = input.substring(searchPos + input.length)
        return (
            <>
                {before}
                <Link href={match}>{match}</Link>
                {after}
            </>
        )
    }
    return input
}
