import { createReducer } from "@reduxjs/toolkit"
import {
    requestCustomerData,
    requestCustomerHeaders,
    setCustomerData,
    setCustomerHeaders,
} from "../actions/customerdata"
import { CRColumnHeader, CRDataBody, CRDataPage } from "../app/types"

export interface CRHeaderState {
    headers: CRColumnHeader[][]
    requestingHeaders: boolean
    headerVersion: string
}

export interface CRDataState {
    data: CRDataBody
    page: CRDataPage
    requestingData: boolean
}

const customerDataReducer = createReducer<CRHeaderState & CRDataState>(
    {
        headers: null,
        data: null,
        page: { index: 0, size: 0, total: 0 },
        requestingHeaders: false,
        requestingData: false,
        headerVersion: null,
    },
    (builder) => {
        builder.addCase(requestCustomerHeaders, (state) => {
            return {
                ...state,
                requestingHeaders: true,
            }
        })
        builder.addCase(setCustomerHeaders, (state, action) => {
            return {
                ...state,
                headers: action.payload,
                requestingHeaders: false,
                headerVersion: Date.now().toString(),
            }
        })
        builder.addCase(requestCustomerData, (state) => {
            return {
                ...state,
                requestingData: true,
            }
        })
        builder.addCase(setCustomerData, (state, action) => {
            return {
                ...state,
                data: action.payload.data,
                page: action.payload.page,
                requestingData: false,
            }
        })
    }
)

export default customerDataReducer
