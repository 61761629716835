import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import { SxProps, Theme } from "@mui/material/styles"
import Typography from "@mui/material/Typography"
import React from "react"

export interface PaginationBarProps {
    total: number
    pageIndex: number
    pageSize: number
    onChange?: (startIndex: number, pageSize: number) => void
    sx?: SxProps<Theme>
    disableAll?: boolean
}

function PaginationBar(props: PaginationBarProps) {
    const {
        total,
        pageIndex,
        pageSize,
        onChange,
        sx,
        disableAll = false,
    } = props

    // const [startIndex, setStartIndex] = React.useState(pageIndex * pageSize)
    const disablePrev = pageIndex == 0 || disableAll
    const startIndex = pageIndex * pageSize
    let endIndex = startIndex + pageSize
    let disableNext = false || disableAll
    if (endIndex >= total) {
        endIndex = total
        disableNext = true
    }

    const handlePrev: React.MouseEventHandler<HTMLButtonElement> = () => {
        const newIndex = pageIndex - 1
        setTimeout(() => onChange(newIndex, pageSize))
    }

    const handleNext: React.MouseEventHandler<HTMLButtonElement> = () => {
        const newIndex = pageIndex + 1
        setTimeout(() => onChange(newIndex, pageSize))
    }

    let bottomText = "No records found"
    if (total > 0) {
        bottomText = `${(
            startIndex + 1
        ).toLocaleString()}-${endIndex.toLocaleString()} of ${total.toLocaleString()}`
    }

    return (
        <Stack sx={sx} direction="row" spacing={2} alignItems="center">
            <Button
                variant="contained"
                disabled={disablePrev}
                onClick={handlePrev}
            >
                Previous
            </Button>
            <Button
                variant="contained"
                disabled={disableNext}
                onClick={handleNext}
            >
                Next
            </Button>
            <Typography variant="body1" color="GrayText">
                {bottomText}
            </Typography>
        </Stack>
    )
}

export default PaginationBar
