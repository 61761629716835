import FormHelperText from "@mui/material/FormHelperText"
import TextField from "@mui/material/TextField"
import React, { useState } from "react"
import { FilterCardProps, RangeState } from "."
import { FilterCardContainer } from "./components"
import InputAdornment from "@mui/material/InputAdornment"
import { IntFormatedTextField } from "./numberfields"

function TimeDeltaFilterCard(props: FilterCardProps) {
    const { header, onChange, ...others } = props
    const [state, setState] = useState<RangeState<string>>({
        greater_than: "",
        lesser_than: "",
    })

    const handleChange = (greaterThan: boolean) => {
        return (event: { target: { value: string } }) => {
            setState((prevState) => {
                const nextState = { ...prevState }
                if (greaterThan) {
                    nextState.greater_than = event.target.value
                } else {
                    nextState.lesser_than = event.target.value
                }
                return nextState
            })
        }
    }

    const onApply = () => {
        const result: RangeState<number> = {
            greater_than: null,
            lesser_than: null,
        }
        result.greater_than = parseInt(state.greater_than)
        result.lesser_than = parseInt(state.lesser_than)
        onChange(header, result)
    }

    const applyDisabled = !(state.greater_than || state.lesser_than)

    return (
        <FilterCardContainer
            name={header.name}
            description={header.description}
            onApply={onApply}
            applyDisabled={applyDisabled}
            {...others}
        >
            <TextField
                label="Greater than"
                value={state.greater_than}
                size="small"
                name="greater-than"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">Days</InputAdornment>
                    ),
                    inputComponent: IntFormatedTextField as any, // eslint-disable-line
                }}
                onChange={handleChange(true)}
                autoFocus
            />

            <TextField
                label="Less Than"
                value={state.lesser_than}
                size="small"
                name="less-than"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">Days</InputAdornment>
                    ),
                    inputComponent: IntFormatedTextField as any, // eslint-disable-line
                }}
                onChange={handleChange(false)}
            />
            <FormHelperText id="component-helper-text">
                Set at least one field. If both fields are set, the values will
                be filtered on the indicated range.
            </FormHelperText>
        </FilterCardContainer>
    )
}

export default TimeDeltaFilterCard
