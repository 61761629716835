import * as React from "react"
import { styled } from "@mui/system"

import AppBar from "@mui/material/AppBar"
import Avatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import { deepOrange } from "@mui/material/colors"
import Button from "@mui/material/Button"
import Modal from "@mui/material/Modal"
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined"
import NightlightOutlinedIcon from "@mui/icons-material/NightlightOutlined"
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined"
import MenuIcon from "@mui/icons-material/Menu"

import { User } from "../app/types"
import { useAuth } from "../context/auth"
import Stack from "@mui/material/Stack"
import Paper from "@mui/material/Paper"
import { useAppDispatch } from "../app/hook"
import { setDarkMode } from "../actions"

import Logo from "../../assets/app/opollo_logo.svg"
// import Logo from "../../assets/app/opollo_logo.svg"

interface NavbarProps {
    darkMode: boolean
    title: string
    user: User
    onMenuClick: () => void
}

const Spacer = styled("div")`
    flex-grow: 1;
`

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
}

function Navbar(props: NavbarProps) {
    const { user, title, darkMode, onMenuClick } = props
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null)
    const [open, setOpen] = React.useState(false)
    const isMenuOpen = Boolean(anchorEl)
    const authContext = useAuth()
    const dispatch = useAppDispatch()

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    const handleSignOut = () => {
        authContext.clearAuthToken()
        handleMenuClose()
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
            <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
        </Menu>
    )

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleDarkModeToggle = () => {
        dispatch(setDarkMode(!darkMode))
    }

    const handleMenuClick = () => {
        onMenuClick()
    }

    return (
        <React.Fragment>
            <AppBar
                sx={{
                    boxShadow: `0 1px 0 0 ${
                        darkMode
                            ? "rgba(255, 255, 255, 0.1)"
                            : "rgba(0, 0, 0, 0.1)"
                    }`,
                }}
                elevation={0}
                position="relative"
                color="transparent"
            >
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={handleMenuClick}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Logo css={{ height: "60px" }} />
                    <Typography sx={{ ml: 2 }} variant="h6">
                        {title}
                    </Typography>
                    <Spacer />
                    <IconButton onClick={handleDarkModeToggle} color="inherit">
                        {darkMode ? (
                            <LightModeOutlinedIcon />
                        ) : (
                            <NightlightOutlinedIcon />
                        )}
                    </IconButton>
                    <IconButton onClick={handleOpen} color="inherit">
                        <TipsAndUpdatesOutlinedIcon />
                    </IconButton>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Paper elevation={1} sx={style}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    id="modal-modal-title"
                                    variant="h6"
                                    component="h2"
                                >
                                    Release Notes
                                </Typography>
                                <Button onClick={handleClose}>Close</Button>
                            </Stack>
                            <pre css={{ overflow: "scroll" }}>
                                {require("../docs/releasenotes.md")}
                            </pre>
                        </Paper>
                    </Modal>
                    <IconButton onClick={handleProfileMenuOpen} size="large">
                        <Avatar
                            sx={{
                                color: (theme) =>
                                    theme.palette.getContrastText(
                                        deepOrange[500]
                                    ),
                                backgroundColor: deepOrange[500],
                            }}
                            alt={user.fullname}
                            src={user.url}
                        ></Avatar>
                    </IconButton>
                </Toolbar>
            </AppBar>
            {renderMenu}
        </React.Fragment>
    )
}

export default Navbar
