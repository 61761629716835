import React from "react"
import Link from "@mui/material/Link"
import { styled, Theme } from "@mui/material/styles"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableRow from "@mui/material/TableRow"
import {
    CRColumnHeader,
    CRColumnType,
    CRDataBody,
    shape,
} from "../../app/types"

export interface OPTableBodyProps {
    headers: CRColumnHeader[][]
    data: CRDataBody
    pageIndex: number
    pageSize: number
    onSidebarChange: (header: CRColumnHeader, value: any) => void
}

const EmptyCell = styled("span")(({ theme }) => ({
    color: theme.palette.text.disabled,
}))

interface OPTableCellProps {
    value: string | number | boolean
    type: CRColumnType
}

function CellRenderer(props: OPTableCellProps): React.ReactElement {
    const { value, type } = props
    if (!value) {
        return <EmptyCell>N/A</EmptyCell>
    }
    // https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/53846
    let result: string = null
    switch (type) {
        case CRColumnType.String:
            result = value as string
            break
        case CRColumnType.Boolean:
            result = value ? "True" : "False"
            break
        case CRColumnType.Float:
            result = Number(value).toFixed(2)
            break
        case CRColumnType.Int:
            result = Number(value).toFixed(0)
            break
        case CRColumnType.TimeDelta:
            if (value == "NaT") {
                return <EmptyCell>N/A</EmptyCell>
            }
            result = (Number(value) / 86400).toFixed()
            break
        default:
            result = String(value)
    }
    return result as React.ReactNode & React.ReactElement
}

function OPTableBody(props: OPTableBodyProps) {
    const { headers, data, pageIndex, pageSize, onSidebarChange } = props
    if (!data) {
        return null
    }

    const dataShape = shape(headers, data)
    const flattenHeaders = headers.flatMap((h) => h)

    const changeHandler = (header: CRColumnHeader, value: any) => {
        return () => {
            onSidebarChange(header, value)
        }
    }

    const body = [...Array(dataShape.rows)].map((_, rowIndex) => {
        return (
            <TableRow
                key={rowIndex}
                sx={{
                    backgroundColor: (theme: Theme) =>
                        rowIndex % 2 == 0 ? theme.palette.action.hover : null,
                    "&:hover": {
                        backgroundColor: "rgba(102, 204, 255, 0.15)",
                    },
                }}
            >
                <TableCell
                    sx={{
                        color: (theme: Theme) => theme.palette.text.secondary,
                    }}
                >
                    {pageIndex * pageSize + rowIndex + 1}
                </TableCell>
                {flattenHeaders.map((header) => {
                    const isSidebarType = header.type == CRColumnType.Sidebar
                    const value = data[header.name][rowIndex]

                    let renderedOutput: React.ReactElement
                    // There must be a better way to abstract this out
                    // Challenge in not passing too much args or pre-binding
                    // unused functions
                    if (isSidebarType && value) {
                        renderedOutput = (
                            <Link
                                onClick={changeHandler(header, value)}
                                sx={{ cursor: "pointer" }}
                            >
                                See values
                            </Link>
                        )
                    } else {
                        renderedOutput = (
                            <CellRenderer value={value} type={header.type} />
                        )
                    }

                    return (
                        <TableCell key={`${rowIndex}-${header.name}`}>
                            {renderedOutput}
                        </TableCell>
                    )
                })}
            </TableRow>
        )
    })

    return <TableBody>{body}</TableBody>
}

export default OPTableBody
