import { FormHelperText, TextField } from "@mui/material"
import { useState } from "react"
import { FilterCardProps } from "."
import { FilterCardContainer } from "./components"

function StringFilterCard(props: FilterCardProps) {
    const { header, onChange, ...others } = props
    const [state, setState] = useState<string>("")

    const handleChange: React.ChangeEventHandler<
        HTMLTextAreaElement | HTMLInputElement
    > = (event) => {
        setState(event.currentTarget.value)
    }

    const onDone = () => {
        onChange(header, [state])
    }

    return (
        <FilterCardContainer
            name={header.name}
            description={header.description}
            onApply={onDone}
            applyDisabled={state.length === 0}
            {...others}
        >
            <TextField
                label="Search"
                value={state}
                size="small"
                onChange={handleChange}
                autoFocus
            />
            <FormHelperText id="component-helper-text">
                This filter accepts full or partial strings.
            </FormHelperText>
        </FilterCardContainer>
    )
}

export default StringFilterCard
