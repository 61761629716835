import * as React from "react"
import Typography from "@mui/material/Typography"
import { setPageTitle } from "../actions"
import { useAppDispatch } from "../app/hook"

function view1() {
    const dispatch = useAppDispatch()

    React.useEffect(() => {
        dispatch(setPageTitle("Under Construction"))
        return () => {
            dispatch(setPageTitle(""))
        }
    })
    return (
        <React.Fragment>
            <Typography variant="h4">View 1</Typography>
            <Typography paragraph>
                Aliquam blanditiis veniam sed a iure. Accusamus nisi consequatur
                vero deserunt magnam. Quam laboriosam cumque fugiat sit. Et
                molestiae voluptates voluptatem molestiae voluptate quaerat est.
                Dolorem facilis possimus quia qui.
            </Typography>
        </React.Fragment>
    )
}

export default view1
