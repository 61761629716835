import { createReducer } from "@reduxjs/toolkit"
import { setPageTitle, setDarkMode } from "../actions"

interface AppMetaState {
    pageTitle: string
    darkMode: boolean
}

// Set up base state
const DarkModeFlag = "DARK_MODE"
const darkModeRaw = localStorage.getItem(DarkModeFlag)
let preferDarkMode = false
if (darkModeRaw == null) {
    // preferDarkMode = useMediaQuery("(prefers-color-scheme: dark)")
    preferDarkMode =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
} else {
    preferDarkMode = JSON.parse(darkModeRaw)
}

const appMeta = createReducer<AppMetaState>(
    {
        pageTitle: "",
        darkMode: preferDarkMode,
    },
    (builder) =>
        builder
            .addCase(setPageTitle, (state, action) => {
                return {
                    ...state,
                    pageTitle: action.payload,
                }
            })
            .addCase(setDarkMode, (state, action) => {
                localStorage.setItem(DarkModeFlag, String(action.payload))
                return {
                    ...state,
                    darkMode: action.payload,
                }
            })
)

export default appMeta
