export type CRDataBody = Record<string, CRDataBodyTypes>

export interface CRDataPage {
    index: number
    size: number
    total: number
}

export type CRDataBodyBaseTypes = string | number | boolean

export type CRDataBodyTypes = CRDataBodyBaseTypes[]

export type CRColumnHeader = {
    name: string
    description: string
    type: CRColumnType
    fixedSet: boolean
    providerCatDependent: boolean
    acceptableValues: string[] | Record<string, string[]>
}

export interface CRSidePanelColumnHeader extends CRColumnHeader {
    headers: CRColumnHeader[]
}

export enum CRColumnType {
    String = "str",
    Int = "int",
    DateTime = "datetime",
    Boolean = "bool",
    Float = "float",
    TimeDelta = "timedelta64",
    Sidebar = "sidebar",
}

export function shape(
    headers: CRColumnHeader[][],
    data: CRDataBody
): { rows: number; cols: number } {
    const cols = headers.reduce<number>((previousValue, currentValue) => {
        return previousValue + currentValue.length
    }, 0)

    const rows = Object.values(data).reduce<number>(
        (previousValue, currentValue) => {
            return Math.max(previousValue, currentValue.length)
        },
        0
    )

    return { rows, cols }
}
