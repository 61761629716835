import Box from "@mui/material/Box"
import { SxProps, Theme } from "@mui/material/styles"
import * as React from "react"
import { CRColumnHeader } from "../../app/types"
import { FilterCardValues } from "../filtercard"
import FilterChip from "../filterchip"
import FilterBarPopover from "./filterpopover"

export interface FilterBarProps {
    headers: CRColumnHeader[]
    onFilterChange: (filter: FilteredColumn[]) => void
    disabled?: boolean
    version?: string
    sx?: SxProps<Theme>
}

export interface FilteredColumn {
    header: CRColumnHeader
    values: FilterCardValues
}

interface FilterBarState {
    selected: FilteredColumn[]
    remaining: CRColumnHeader[]
}

function FilterBar(props: FilterBarProps) {
    const { headers, onFilterChange, sx, version, disabled = false } = props
    // State variables
    const [selection, setSelection] = React.useState<FilterBarState>({
        selected: [],
        remaining: [],
    })

    React.useEffect(() => {
        setSelection({
            selected: [],
            remaining: [...headers],
        })
    }, [version])

    const dispatchFilterChange = (selection: FilteredColumn[]) => {
        setTimeout(() => {
            onFilterChange(selection)
        })
    }

    const handleAddFilter = (
        header: CRColumnHeader,
        values: FilterCardValues
    ) => {
        setSelection((prevSelection) => {
            const nextSelection = { ...prevSelection }
            const index = nextSelection.remaining.indexOf(header)
            nextSelection.remaining.splice(index, 1)
            nextSelection.selected.push({
                header,
                values,
            })
            dispatchFilterChange(nextSelection.selected)
            return nextSelection
        })
    }

    const handleRemoveFilter = (header: CRColumnHeader) => {
        setSelection((prevSelection) => {
            const selected = prevSelection.selected.filter(
                (filteredColumn: FilteredColumn) => {
                    return filteredColumn.header.name != header.name
                }
            )
            const selectedHeaderNames = selected.map(
                (filteredColumn) => filteredColumn.header.name
            )
            const remaining = [...headers].filter((header) => {
                return selectedHeaderNames.indexOf(header.name) == -1
            })
            dispatchFilterChange(selected)
            return {
                selected,
                remaining,
            }
        })
    }

    return (
        <Box
            sx={{
                ...sx,
                width: "100%",
            }}
        >
            {selection.selected.map(
                (filteredCol: FilteredColumn, index: number) => {
                    return (
                        <FilterChip
                            key={`${filteredCol.header.name}-${index}`}
                            selectedColumn={filteredCol}
                            onRemove={handleRemoveFilter}
                            disabled={disabled}
                        />
                    )
                }
            )}
            <FilterBarPopover
                disabled={disabled}
                addFilter={handleAddFilter}
                {...selection}
            />
        </Box>
    )
}

export default FilterBar
