import { AnyAction } from "@reduxjs/toolkit"
import { ThunkAction } from "@reduxjs/toolkit"
import { RootState } from "../app/store"

/* Thunks
 * These are basically async actions that can be dispatched and chained
 * See https://github.com/reduxjs/redux-thunk for why this is useful
 *
 * Types:
 *   ThunkAction<ResultType, StateType, ExtraArgumentType, ActionType extends Action>
 */
export type ThunkResult<R> = ThunkAction<R, RootState, undefined, AnyAction>

export * from "./customerdata"
export * from "./user"
export * from "./error"
export * from "./appmeta"
