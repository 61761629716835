import Link from "@mui/material/Link"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Box from "@mui/system/Box"
import { MouseEventHandler, useState } from "react"
import { FilterCardProps } from "."
import { FilterCardContainer } from "./components"
import Stack from "@mui/material/Stack"

interface FixedSetState {
    selection: string[]
}

function FixedSetFilterCard(props: FilterCardProps) {
    const { header, onChange, ...others } = props
    const values = header.acceptableValues as string[]

    const [state, setState] = useState<FixedSetState>({
        selection: [],
    })
    // Here we set up all the callbacks for the UI elements
    const isChecked = (id: string): boolean => {
        return state.selection.includes(id)
    }

    const changeHandler = (id: string) => {
        return (
            _event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
        ) => {
            setState((prevState) => {
                const newState = Object.assign({}, prevState)
                const index = newState.selection.indexOf(id)
                if (checked && index == -1) {
                    newState.selection.push(id)
                } else if (!checked && index >= 0) {
                    newState.selection.splice(index, 1)
                }
                return newState
            })
        }
    }

    const selectAllHandler: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault()
        setState((prevState) => {
            const newState = { ...prevState }
            newState.selection = [...values]
            return newState
        })
    }

    const selectNoneHandler: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault()
        setState((prevState) => {
            const newState = { ...prevState }
            newState.selection = []
            return newState
        })
    }

    const onApply = () => {
        onChange(header, state.selection)
    }

    // Render all the individual selection items
    const renderedFields: React.ReactNode[] = []
    for (const value of values) {
        renderedFields.push(
            <FormControlLabel
                key={value}
                control={
                    <Checkbox
                        checked={isChecked(value)}
                        onChange={changeHandler(value)}
                        name={value}
                    />
                }
                label={value}
            />
        )
    }

    const applyDisabled = state.selection.length === 0

    return (
        <FilterCardContainer
            name={header.name}
            description={header.description}
            onApply={onApply}
            applyDisabled={applyDisabled}
            {...others}
        >
            <Box
                sx={{
                    flex: "0 1 auto",
                    overflow: "scroll",
                    borderBottom: "1px solid #DEDEDE",
                }}
            >
                <FormGroup>{renderedFields}</FormGroup>
            </Box>
            <Stack direction="row" spacing={2}>
                <Link
                    onClick={selectAllHandler}
                    variant="body1"
                    underline="none"
                    component="button"
                >
                    Select All
                </Link>
                <Link
                    onClick={selectNoneHandler}
                    variant="body1"
                    underline="none"
                    component="button"
                >
                    Select None
                </Link>
            </Stack>
        </FilterCardContainer>
    )
}

export default FixedSetFilterCard
