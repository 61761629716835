import * as React from "react"
import Alert from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import { styled } from "@mui/material/styles"
import Container, { ContainerProps } from "@mui/material/Container"
import { SerializedError } from "@reduxjs/toolkit"
import Paper from "@mui/material/Paper"

interface Props extends React.ComponentPropsWithRef<React.ElementType> {
    error: SerializedError
}

interface State {
    hasError: boolean
    error: SerializedError
}

const EmptyContainer = styled(Container)<ContainerProps>({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    // alignItems: "center",
    height: "100%",
})

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        const { error } = props
        super(props)
        if (error) {
            this.state = { error, hasError: true }
        } else {
            this.state = { error, hasError: false }
        }
    }

    static getDerivedStateFromError(error: Error): State {
        return {
            hasError: true,
            error,
        }
    }

    static getDerivedStateFromProps(nextProps: Props) {
        const { error } = nextProps
        return {
            error,
            hasError: !!error,
        }
    }

    // componentDidCatch(error, errorInfo) {
    // logErrorToMyService(error, errorInfo);
    // }

    render(): React.ReactNode {
        const { hasError, error } = this.state
        const { children } = this.props

        if (hasError) {
            // You can render any custom fallback UI
            return (
                <EmptyContainer>
                    <Alert
                        severity="error"
                        sx={{ width: "100%", overflow: "scroll" }}
                    >
                        <AlertTitle>{error.message}</AlertTitle>
                    </Alert>
                    {error.stack ? (
                        <Paper
                            elevation={1}
                            sx={{
                                padding: 2,
                                maxHeight: "400px",
                                width: "100%",
                                overflow: "scroll",
                            }}
                        >
                            <pre>{error.stack}</pre>
                        </Paper>
                    ) : null}
                </EmptyContainer>
            )
        }
        return children
    }
}
