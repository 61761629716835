import * as React from "react"
import { connect, ConnectedProps } from "react-redux"
import { Route, Routes, Navigate } from "react-router-dom"

import { styled } from "@mui/material/styles"
import { RootState } from "../app/store"

import Navbar from "../components/navbar"
import Sidebar from "../components/sidebar"
import View1 from "./view1"
import CustomerResearch from "./customerresearch"
import ErrorBoundary from "../components/errorboundary"
import LoadingView from "../components/loadingview"
import { useAppDispatch } from "../app/hook"
import { setCurrentUser } from "../actions"

const ContainerDiv = styled("div")({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
})

const AppBody = styled("div")({
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    // This insane min-width declaration help prevent the table from blowing out the layout
    // See https://css-tricks.com/preventing-a-grid-blowout/ for details
    minWidth: 0,
    minHeight: 0,
    overflow: "hidden",
})

const Content = styled("main")(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    overflow: "scroll",
}))

function Workspace(props: ConnectedProps<typeof connector>) {
    const { appMeta, currentUser, error } = props
    const dispatch = useAppDispatch()
    const [showSidebar, setShowSidebar] = React.useState<boolean>(true)

    // const location = useLocation();
    // const background = location.state && location.state.background;
    document.title = "Opollo Sales Dashboard"

    React.useEffect(() => {
        if (!currentUser) {
            dispatch(
                setCurrentUser({
                    token: "",
                    id: "test_id",
                    url: "",
                    fullname: "Test User",
                    email: "test@test.com",
                })
            )
        }
    }, [currentUser])

    const handleMenuClick = () => {
        setShowSidebar(!showSidebar)
    }

    return (
        <ErrorBoundary error={error}>
            <LoadingView loading={!currentUser}>
                <ContainerDiv>
                    <Navbar
                        darkMode={appMeta.darkMode}
                        user={currentUser}
                        title={appMeta.pageTitle}
                        onMenuClick={handleMenuClick}
                    />
                    <AppBody>
                        <Sidebar open={showSidebar} />
                        <Content>
                            <Routes>
                                <Route
                                    path="/customerresearch"
                                    element={<CustomerResearch />}
                                />
                                <Route path="/view1" element={<View1 />} />
                                <Route
                                    path="*"
                                    element={
                                        <Navigate
                                            replace
                                            to="/customerresearch"
                                        />
                                    }
                                />
                            </Routes>
                        </Content>
                    </AppBody>
                </ContainerDiv>
            </LoadingView>
        </ErrorBoundary>
    )
}

// Redux boilerplate
function mapStateToProps(state: RootState) {
    const { appMeta, currentUser, error } = state
    return { appMeta, currentUser, error }
}
const connector = connect(mapStateToProps)
export default connector(Workspace)
