import * as React from "react"
import { connect, Provider as ReduxProvider, ConnectedProps } from "react-redux"
import { BrowserRouter as Router } from "react-router-dom"

import {
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
} from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"

import { AuthProvider, useAuth } from "./context/auth"
// import SignUp from "./pages/signup"
// import SignIn from "./pages/signin"
import Workspace from "./pages/workspace"
import store, { RootState } from "./app/store"
import { LocalizationProvider } from "@mui/lab"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import { getDesignTokens } from "./theme"

function AuthenticatedApp() {
    // const authContext = useAuth()
    return <Workspace />
    //     return authContext.hasAuthToken() ? (
    //         <Workspace />
    //     ) : (
    //         <Routes>
    //             <Route path="/signup" element={<SignUp />} />
    //             <Route path="/signin" element={<SignIn />} />
    //             <Route path="*" element={<Navigate replace to="/signup" />} />
    //         </Routes>
    //     )
}

function ConnectedRoot(props: ConnectedProps<typeof connector>) {
    const { appMeta } = props
    const theme = React.useMemo(
        () => createTheme(getDesignTokens(appMeta.darkMode ? "dark" : "light")),
        [appMeta.darkMode]
    )
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <AuthProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Router>
                        <AuthenticatedApp />
                    </Router>
                </LocalizationProvider>
            </AuthProvider>
        </ThemeProvider>
    )
}

function App() {
    return (
        <StyledEngineProvider injectFirst>
            <ReduxProvider store={store}>
                <StatefulApp />
            </ReduxProvider>
        </StyledEngineProvider>
    )
}

function mapStateToProps(state: RootState) {
    const { appMeta } = state
    return { appMeta }
}
const connector = connect(mapStateToProps)
const StatefulApp = connector(ConnectedRoot)
export default App
