import { createAction } from "@reduxjs/toolkit"
import { hasError, ThunkResult } from "."
import {
    CRColumnHeader,
    CRColumnType,
    CRDataBody,
    CRDataBodyTypes,
    CRDataPage,
    CRSidePanelColumnHeader,
} from "../app/types"
import { getSchema } from "../request/customerdata"
import schemaGroups from "../app/types/schemagroups.json"
import { getCustomerResearchData } from "../request/customerdata"
import { FilteredColumn } from "../components/filterbar"

export const requestCustomerHeaders = createAction("customer/headers/request")

export const setCustomerHeaders = createAction<CRColumnHeader[][]>(
    "customer/headers/set"
)

export function fetchHeaders(): ThunkResult<Promise<void>> {
    return async (dispatch): Promise<void> => {
        dispatch(requestCustomerHeaders())
        try {
            const schemaResponse = await getSchema()
            // Here we construct the basic data structure
            const columnHeaders: Record<string, CRColumnHeader> = {}
            // Column descriptions
            const headerAndDescObj: Record<string, string> =
                schemaResponse["cms_dataset_dataframe_column_descriptions_dict"]
            for (const [headerId, description] of Object.entries(
                headerAndDescObj
            )) {
                const columnHeader: CRColumnHeader = {
                    name: headerId,
                    description,
                    type: null,
                    fixedSet: false,
                    providerCatDependent: false,
                    acceptableValues: null,
                }
                columnHeaders[headerId] = columnHeader
            }
            // Column types
            const columnTypes: Record<string, string> =
                schemaResponse["cms_dataset_dataframe_column_dtypes_dict"]
            for (const [headerId, typeStr] of Object.entries(columnTypes)) {
                columnHeaders[headerId].type = typeStr as CRColumnType
            }
            // Column Values
            type ColumnValuesValue = string[] | Record<string, string[]>
            const columnValues: Record<string, ColumnValuesValue> =
                schemaResponse["string_dtype_columns_dropdown_dict"]
            for (const [headerId, values] of Object.entries(columnValues)) {
                if (values instanceof Array) {
                    columnHeaders[headerId].fixedSet = true
                    columnHeaders[headerId].acceptableValues = values
                } else {
                    columnHeaders[headerId].fixedSet = true
                    columnHeaders[headerId].providerCatDependent = true
                    columnHeaders[headerId].acceptableValues = values
                }
            }
            // Build Complex Columns
            const complexColumns = Object.values(columnHeaders).filter(
                (item) => item.type == CRColumnType.Sidebar
            )
            const ComplexColumnsDict: Record<string, string> = {
                "Opollo Potential Leads":
                    "opollo_individual_potential_lead_columns_list",
                "Opollo Exchange Potential Leads":
                    "opollo_exchange_individual_potential_lead_columns_list",
            }
            for (const column of complexColumns) {
                if (column.name in ComplexColumnsDict) {
                    const groupedColumn = column as CRSidePanelColumnHeader
                    groupedColumn.headers = []
                    const complex_key = ComplexColumnsDict[column.name]
                    for (const itemId of schemaResponse[complex_key]) {
                        const targetCol = columnHeaders[itemId]
                        groupedColumn.headers.push(targetCol)
                        delete columnHeaders[itemId]
                    }
                }
            }

            // Build Final dataframe
            const headers: CRColumnHeader[][] = []
            for (const schemaGroup of schemaGroups) {
                const columnHeaderSet: CRColumnHeader[] = []
                for (const headerItemId of schemaGroup) {
                    const column = columnHeaders[headerItemId]
                    if (!column) {
                        console.warn(`${headerItemId} not found`)
                    } else {
                        columnHeaderSet.push(column)
                        delete columnHeaders[headerItemId]
                    }
                }
                headers.push(columnHeaderSet)
            }
            // For debugging purposes
            for (const key of Object.keys(columnHeaders)) {
                console.warn(`${key} is not being assigned`)
            }
            // Finally we set data into state
            dispatch(setCustomerHeaders(headers))
        } catch (e) {
            if (e instanceof Error) {
                dispatch(hasError(e))
            } else {
                console.error(e)
            }
        }
    }
}

export const setCustomerData =
    createAction<{ data: CRDataBody; page: CRDataPage }>("customer/data/set")

export const requestCustomerData = createAction<string>("customer/data/request")

/*
 * This function calls the Sales Dashboard Customer Research API and processes the data
 *
 * TODO - add filtering
 * TODO - add pagination
 */
export function fetchCustomerData(
    filters: FilteredColumn[] = null,
    pageIndex = 0,
    pageSize = 100
): ThunkResult<Promise<void>> {
    return async (dispatch): Promise<void> => {
        dispatch(requestCustomerData())
        try {
            const crResp = await getCustomerResearchData(
                filters,
                pageIndex,
                pageSize
            )
            // const state = getState()
            const data: Record<string, CRDataBodyTypes> = {}
            const dataColumns = crResp["filtered_cms_dataset_dataframe_dict"]
            for (const [key, value] of Object.entries(dataColumns)) {
                data[key] = value as CRDataBodyTypes
            }

            const dataPage = crResp["page_metadata_dict"]
            const page: CRDataPage = {
                size: dataPage["page_size"],
                index: dataPage["page_index"],
                total: dataPage["total"],
            }

            dispatch(setCustomerData({ data, page }))
        } catch (e) {
            if (e instanceof Error) {
                dispatch(hasError(e))
            } else {
                console.error(e)
            }
        }
    }
}
