import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Tooltip from "@mui/material/Tooltip"
import { CRColumnHeader } from "../../app/types"

export interface TableHeaderProps {
    headers: CRColumnHeader[][]
}

function OPTableHeader(props: TableHeaderProps) {
    const { headers } = props
    if (!headers) {
        return null
    }

    const headerNodes: React.ReactNode[] = headers.map(
        (headerSet, setIndex) => {
            return headerSet.map((header, itemIndex) => {
                return (
                    <TableCell
                        sx={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        }}
                        key={`${setIndex}-${itemIndex}`}
                    >
                        <Tooltip title={header.description} arrow>
                            <span>{header && header.name}</span>
                        </Tooltip>
                    </TableCell>
                )
            })
        }
    )
    const cols: React.ReactNode[] = headers.map((headerSet, setIndex) => {
        return headerSet.map((_header, itemIndex) => {
            return (
                <col
                    css={{ minWidth: "100px" }}
                    key={`${setIndex}-${itemIndex}`}
                />
            )
        })
    })
    return (
        <>
            <colgroup>
                <col />
                {cols}
            </colgroup>
            <TableHead>
                <TableRow>
                    <TableCell />
                    {headerNodes}
                </TableRow>
            </TableHead>
        </>
    )
}

export default OPTableHeader
