import { createAction } from "@reduxjs/toolkit"
import { hasError, ThunkResult } from "."
import { AppDispatch } from "../app/store"
import { User } from "../app/types"
import { getUser } from "../request"

const requestCurrentUser = createAction("REQUEST_CUR_USER")
export const setCurrentUser = createAction<User>("SET_CUR_USER")

export function fetchCurrentUserIfNeeded(): ThunkResult<Promise<void>> {
    return async (dispatch, getState): Promise<void> => {
        const state = getState()
        if (!state.currentUser) {
            return dispatch(fetchCurrentUser())
        }
    }
}

function fetchCurrentUser(): ThunkResult<Promise<void>> {
    return async (dispatch: AppDispatch): Promise<void> => {
        dispatch(requestCurrentUser())
        return getUser()
            .then((userResp) => {
                const user = userResp.user
                dispatch(setCurrentUser(user))
            })
            .catch((error: Error) => {
                dispatch(hasError(error))
            })
    }
}
