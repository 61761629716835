import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Close from "@mui/icons-material/Close"
import { FilteredColumn } from "../filterbar"
import IconButton from "@mui/material/IconButton"
import Stack from "@mui/material/Stack"
import Paper from "@mui/material/Paper"
import { renderValues } from "../../utils/customerdata"
import { CRColumnHeader } from "../../app/types"

export interface FilterChipProps {
    selectedColumn: FilteredColumn
    disabled?: boolean
    onRemove: (header: CRColumnHeader) => void
}

function FilterChip(props: FilterChipProps) {
    const { selectedColumn, onRemove, disabled = false } = props

    const closeHandler = () => {
        onRemove(selectedColumn.header)
    }

    return (
        <Paper
            elevation={1}
            sx={{
                backgroundColor: (theme) => theme.palette.backgrounds.main,
                maxWidth: "450px",
                paddingLeft: 1,
                mr: 1,
                borderRadius: "10px",
                display: "inline-block",
            }}
        >
            <Stack direction="row" alignItems="center">
                <Box
                    sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    <Typography component="span" variant="body2">
                        {selectedColumn.header.name}
                    </Typography>
                    <Typography
                        component="span"
                        variant="body2"
                        color="text.secondary"
                        sx={{
                            ml: 1,
                        }}
                    >
                        {renderValues(
                            selectedColumn.header,
                            selectedColumn.values
                        )}
                    </Typography>
                </Box>
                <IconButton
                    size="small"
                    disabled={disabled}
                    onClick={closeHandler}
                >
                    <Close />
                </IconButton>
            </Stack>
        </Paper>
    )
}
export default FilterChip
