import * as React from "react"
import Close from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import {
    CRColumnType,
    CRDataBodyBaseTypes,
    CRSidePanelColumnHeader,
} from "../../app/types"
import { linkify } from "../../utils"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"

const EmptyValueSpan = styled("span")(({ theme }) => ({
    color: theme.palette.text.disabled,
}))

const ItemContainer = styled("ul")(({ theme }) => ({
    paddingBottom: "10px",
    borderBottom: `1px solid ${theme.palette.divider}`,
    listStyle: "None",
    paddingLeft: 0,
    li: {
        marginBottom: "5px",
    },
    "&:last-of-type": {
        paddingBottom: 0,
        borderBottom: "none",
    },
}))

export interface SidePanelProps {
    header: CRSidePanelColumnHeader
    values: Record<string, CRDataBodyBaseTypes>[]
    open?: boolean
    onClose: () => void
}

function SidePanelContent(props: {
    header: CRSidePanelColumnHeader
    values: Record<string, CRDataBodyBaseTypes>
}) {
    const { values, header } = props
    const results: React.ReactElement[] = []
    for (const subheader of header.headers) {
        // The typing here gets a bit convoluted since it's javascripty
        // Worth cleaning up in the future
        let value: CRDataBodyBaseTypes | React.ReactElement =
            values[subheader.name]
        if (!value) {
            value = <EmptyValueSpan>N/A</EmptyValueSpan>
        } else if (subheader.type == CRColumnType.Boolean) {
            value = value ? "True" : "False"
        } else if (subheader.type == CRColumnType.String) {
            value = linkify(value as string)
        }
        results.push(
            <li key={subheader.name}>
                <Typography
                    variant="body2"
                    sx={{
                        color: (theme) => theme.palette.text.secondary,
                    }}
                >
                    {subheader.name}
                </Typography>
                <Typography>{value}</Typography>
            </li>
        )
    }
    return <ItemContainer>{results}</ItemContainer>
}

function SidePanel(props: SidePanelProps) {
    const { header, values, open, onClose } = props
    if (!open) {
        return null
    }
    return (
        <Paper
            sx={{
                width: "450px",
                marginLeft: "30px",
                borderRadius: "15px",
                flexShrink: 0,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                p: 2,
            }}
        >
            <Stack direction="row" alignItems="flex-end" sx={{ mb: 3 }}>
                <Typography variant="h6" sx={{ flexGrow: 1 }}>
                    {header.name}
                </Typography>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </Stack>
            <Box
                sx={{
                    overflow: "scroll",
                }}
            >
                {values.map((value) => (
                    <SidePanelContent header={header} values={value} />
                ))}
            </Box>
        </Paper>
    )
}
export default SidePanel
