import { combineReducers } from "@reduxjs/toolkit"

import customerData from "./customerdata"
import { currentUser } from "./user"
import error from "./error"
import appMeta from "./appmeta"

const rootReducer = combineReducers({
    appMeta,
    currentUser,
    error,
    customerData,
})
export default rootReducer
