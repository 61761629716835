import * as React from "react"

import Paper from "@mui/material/Paper"
import List from "@mui/material/List"

import RouteListItem from "./routeListItem"
import { useLocation } from "react-router-dom"
import Box from "@mui/material/Box"

const drawerWidth = 240

export interface SidebarProps {
    open?: boolean
}

function Sidebar(props: SidebarProps) {
    const { open = true } = props
    const location = useLocation()

    return (
        <Paper
            sx={{
                display: "flex",
                flexShrink: 0,
                width: open ? drawerWidth : 0,
                height: "100%",
                transition: "width 0.2s",
                overflow: "hidden",
            }}
        >
            <Box
                sx={{
                    minWidth: drawerWidth,
                }}
            >
                <List
                    sx={{
                        pt: 2,
                    }}
                >
                    {[
                        {
                            text: "Customer Research",
                            path: "/customerresearch",
                        },
                        { text: "Surgery Demand", path: "/view1" },
                    ].map((item, index) => (
                        <RouteListItem
                            selected={location.pathname === item.path}
                            key={index}
                            to={item.path}
                            primary={item.text}
                        />
                    ))}
                </List>
            </Box>
        </Paper>
    )
}

export default Sidebar
