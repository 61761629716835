import CircularProgress from "@mui/material/CircularProgress"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

export interface LoadingTagProps {
    loading: boolean
}

function LoadingTag(props: LoadingTagProps) {
    const { loading } = props

    let loadingViews = null
    if (loading) {
        loadingViews = (
            <>
                <CircularProgress size={20} />
                <Typography variant="body1">Updating</Typography>
            </>
        )
    }

    return (
        <Stack
            sx={{
                width: "100px",
                height: "40px",
            }}
            direction="row"
            spacing={1}
            alignItems="center"
        >
            {loadingViews}
        </Stack>
    )
}

export default LoadingTag
