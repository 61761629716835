import TextField from "@mui/material/TextField"
import DatePicker from "@mui/lab/DatePicker"
import { useState } from "react"
import { FilterCardProps, RangeState } from "."
import { FilterCardContainer } from "./components"
import FormHelperText from "@mui/material/FormHelperText"

function DateTimeFilterCard(props: FilterCardProps) {
    const { header, onChange, ...others } = props
    const [state, setState] = useState<RangeState<string>>({
        greater_than: null,
        lesser_than: null,
    })

    const handleChange = (greaterThan: boolean) => {
        return (date: string) => {
            setState((prevState) => {
                const nextState = { ...prevState }
                if (greaterThan) {
                    nextState.greater_than = date
                } else {
                    nextState.lesser_than = date
                }
                return nextState
            })
        }
    }

    const onDone = () => {
        onChange(header, state)
    }
    const applyDisabled = !(state.greater_than || state.lesser_than)

    return (
        <FilterCardContainer
            name={header.name}
            description={header.description}
            onApply={onDone}
            applyDisabled={applyDisabled}
            {...others}
        >
            <DatePicker
                label="From"
                value={state.greater_than}
                onChange={handleChange(true)}
                renderInput={(params) => (
                    <TextField size="small" {...params} autoFocus />
                )}
            />
            <DatePicker
                label="Until"
                value={state.lesser_than}
                onChange={handleChange(false)}
                renderInput={(params) => <TextField size="small" {...params} />}
            />
            <FormHelperText id="component-helper-text">
                Set at least one field. If both fields are set, the values will
                be filtered on the indicated range.
            </FormHelperText>
        </FilterCardContainer>
    )
}

export default DateTimeFilterCard
