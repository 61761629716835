import Link from "@mui/material/Link"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Box from "@mui/system/Box"
import { MouseEventHandler, useState } from "react"
import { FilterCardProps } from "."
import { FilterCardContainer } from "./components"
import Stack from "@mui/material/Stack"
import FormLabel from "@mui/material/FormLabel"

type DepFixedSetState = Record<string, boolean>

function DepFixedSetFilterCard(props: FilterCardProps) {
    const { header, onChange, filteredCols, ...others } = props

    const allFixedSetValues = header.acceptableValues as Record<
        string,
        string[]
    >
    // We first figure out if there is a subset of options selected
    let displayValues: Record<string, string[]> = {}
    if (filteredCols && filteredCols.length > 0) {
        const filteredProviderCat = filteredCols.find((value) => {
            return value.header.name == "Provider Category"
        })
        if (filteredProviderCat) {
            const filteredValues = filteredProviderCat.values as string[]
            for (const key of filteredValues) {
                displayValues[key] = allFixedSetValues[key]
            }
        }
    } else {
        displayValues = { ...allFixedSetValues }
    }
    // We set up the internal state
    // The internal state stores "section---option": true/false
    // TODO - process incoming state
    const initialState: DepFixedSetState = {}
    const separator = "---"
    const getKey = (section: string, option: string) => {
        return `${section}${separator}${option}`
    }
    for (const [section, options] of Object.entries(displayValues)) {
        for (const option of options) {
            const longKey = getKey(section, option)
            initialState[longKey] = false
        }
    }
    const [state, setState] = useState<DepFixedSetState>(initialState)
    // Here we set up all the callbacks for the UI elements
    const isChecked = (section: string, option: string): boolean => {
        const longKey = getKey(section, option)
        return state[longKey]
    }

    const changeHandler = (section: string, option: string) => {
        return (
            _event: React.ChangeEvent<HTMLInputElement>,
            checked: boolean
        ) => {
            const longKey = getKey(section, option)
            setState((prevState) => {
                const newState = Object.assign({}, prevState)
                newState[longKey] = checked
                return newState
            })
        }
    }

    const selectAllHandler: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault()
        setState((prevState) => {
            const newState = { ...prevState }
            for (const key of Object.keys(newState)) {
                newState[key] = true
            }
            return newState
        })
    }

    const selectNoneHandler: MouseEventHandler<HTMLAnchorElement> = (event) => {
        event.preventDefault()
        setState((prevState) => {
            const newState = { ...prevState }
            for (const key of Object.keys(newState)) {
                newState[key] = false
            }
            return newState
        })
    }

    const onApply = () => {
        // Transform the data to the format expected by the service
        const result: Record<string, string[]> = {}
        const selectedItems = Object.entries(state)
            .map((item) => (item[1] ? item[0] : null))
            .filter((x) => x)
        for (const item of selectedItems) {
            const [section, option] = item.split(separator)
            if (!result[section]) {
                result[section] = []
            }
            result[section].push(option)
        }
        onChange(header, result)
    }

    // Render all the individual selection items
    const renderedFields: React.ReactNode[] = []
    for (const [title, options] of Object.entries(displayValues)) {
        renderedFields.push(
            <FormLabel sx={{ mt: 1, mb: 1 }} key={title}>
                {title}
            </FormLabel>
        )
        for (const option of options) {
            renderedFields.push(
                <FormControlLabel
                    key={`${title}-${option}`}
                    control={
                        <Checkbox
                            checked={isChecked(title, option)}
                            onChange={changeHandler(title, option)}
                            name={option}
                        />
                    }
                    label={option}
                />
            )
        }
    }

    const applyDisabled = !Object.values(state).reduce(
        (prevVal, currentVal) => prevVal || currentVal
    )

    return (
        <FilterCardContainer
            name={header.name}
            description={header.description}
            onApply={onApply}
            applyDisabled={applyDisabled}
            {...others}
        >
            <Box
                sx={{
                    flex: "0 1 auto",
                    overflow: "scroll",
                    borderBottom: "1px solid #DEDEDE",
                }}
            >
                <FormGroup>{renderedFields}</FormGroup>
            </Box>
            <Stack direction="row" spacing={2}>
                <Link
                    onClick={selectAllHandler}
                    variant="body1"
                    underline="none"
                    component="button"
                >
                    Select All
                </Link>
                <Link
                    onClick={selectNoneHandler}
                    variant="body1"
                    underline="none"
                    component="button"
                >
                    Select None
                </Link>
            </Stack>
        </FilterCardContainer>
    )
}

export default DepFixedSetFilterCard
