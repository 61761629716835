import { axiosInstance } from "."
import { FilteredColumn } from "../components/filterbar"
import { FilterCardValues } from "../components/filtercard"

export async function getSchema(): Promise<any> {
    try {
        const response = await axiosInstance.get("/schema")
        return response.data
    } catch (e) {
        const errorMsg = e.response?.data?.error
        const error = errorMsg ? new Error(errorMsg) : e
        throw error
    }
}

function serialize(filters: FilteredColumn[]) {
    const result: Record<string, FilterCardValues> = {}
    for (const filter of filters) {
        result[filter.header.name] = filter.values
    }
    return result
}

export async function getCustomerResearchData(
    filters: FilteredColumn[] = null,
    pageIndex: number,
    pageSize: number
): Promise<any> {
    const params = {
        pageIndex,
        pageSize,
        filters: <string>null,
    }
    if (filters) {
        const filtersParams = JSON.stringify(serialize(filters))
        params.filters = filtersParams
    }
    try {
        const response = await axiosInstance.get("/data", { params })
        return response.data
    } catch (e) {
        const errorMsg = e.response?.data?.error
        const error = errorMsg ? new Error(errorMsg) : e
        throw error
    }
}
