import FormControlLabel from "@mui/material/FormControlLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import { useState } from "react"
import { FilterCardProps } from "."
import { FilterCardContainer } from "./components"

function BooleanFilterCard(props: FilterCardProps) {
    const { header, onChange, ...others } = props
    const [state, setState] = useState<boolean>(true)

    const onApply = () => {
        onChange(header, state)
    }
    const strToBool = (value: string) => {
        return value == "True" ? true : false
    }
    const boolToStr = (value: boolean) => {
        return value ? "True" : "False"
    }

    return (
        <FilterCardContainer
            name={header.name}
            description={header.description}
            onApply={onApply}
            {...others}
        >
            <RadioGroup
                // defaultValue={boolToStr(state)}
                value={boolToStr(state)}
                onChange={(_e, value) => setState(strToBool(value))}
                name={header.name}
            >
                <FormControlLabel
                    value="True"
                    control={<Radio />}
                    label="True"
                />
                <FormControlLabel
                    value="False"
                    control={<Radio />}
                    label="False"
                />
            </RadioGroup>
        </FilterCardContainer>
    )
}

export default BooleanFilterCard
