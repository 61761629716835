import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Popover from "@mui/material/Popover"
import React from "react"
import TextField from "@mui/material/TextField"
import { CRColumnHeader } from "../../app/types"
import SearchIcon from "@mui/icons-material/Search"
import InputAdornment from "@mui/material/InputAdornment"
import FilterCard, { FilterCardValues } from "../filtercard"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import { FilteredColumn } from "."
import Typography from "@mui/material/Typography"
import { toVerboseType } from "../../utils/customerdata"

interface FilterBarPopoverProps {
    selected: FilteredColumn[]
    remaining: CRColumnHeader[]
    disabled: boolean
    addFilter: (header: CRColumnHeader, values: FilterCardValues) => void
}

function FilterBarPopover(props: FilterBarPopoverProps) {
    const { selected, remaining, disabled, addFilter } = props
    // Component state
    const [searchText, setSearchText] = React.useState<string>("")
    const [filterableCols, setFilterableCols] =
        React.useState<CRColumnHeader[]>(remaining)
    const [activeFilter, setActiveFilter] = React.useState<CRColumnHeader>(null)
    const [anchorEl, setAnchorEl] =
        React.useState<HTMLButtonElement | null>(null)
    const open = Boolean(anchorEl)
    // Side effects
    const updateSearchTerm = (searchTerm: string) => {
        setSearchText(searchTerm)
        const lcSearchTerm = searchTerm.toLowerCase()
        const filteredList = [...remaining].filter(
            (value) => value.name.toLowerCase().indexOf(lcSearchTerm) >= 0
        )
        setFilterableCols(filteredList)
    }

    React.useEffect(() => {
        updateSearchTerm(searchText)
    }, [remaining.length])
    /* Event handlers */
    // Button events
    const handleAddFilterClick = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        setActiveFilter(null)
        setAnchorEl(event.currentTarget)
    }
    // Popover events
    const handlePopoverClose = () => setAnchorEl(null)
    // List item events
    const selectFilter = (header: CRColumnHeader) => {
        return () => {
            setActiveFilter(header)
        }
    }
    // Search Field (e.g. filter) events
    const handleSearchFieldChange: React.ChangeEventHandler<HTMLInputElement> =
        (event) => {
            const searchTerm = event.target.value
            updateSearchTerm(searchTerm)
        }
    // FilterCard events
    const handleFilterCardAdd = (
        header: CRColumnHeader,
        values: FilterCardValues
    ) => {
        setAnchorEl(null)
        addFilter(header, values)
    }

    const handleFilterCardCancel = () => setAnchorEl(null)
    // Rendering
    return (
        <>
            <Button disabled={disabled} onClick={handleAddFilterClick}>
                Add Filter
            </Button>
            <Popover
                id="filter-bar"
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        maxWidth: "430px",
                        maxHeight: "500px",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {activeFilter ? (
                        <FilterCard
                            header={activeFilter}
                            filteredCols={selected}
                            onCancel={handleFilterCardCancel}
                            onChange={handleFilterCardAdd}
                        />
                    ) : (
                        <>
                            <Box
                                sx={{
                                    p: 2,
                                    pb: 1,
                                }}
                            >
                                <TextField
                                    placeholder="Search fields"
                                    value={searchText}
                                    size="small"
                                    fullWidth
                                    autoFocus
                                    onChange={handleSearchFieldChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                            <Box sx={{ overflow: "scroll" }}>
                                <List component="nav">
                                    {filterableCols.map(
                                        (
                                            header: CRColumnHeader,
                                            index: number
                                        ) => {
                                            return (
                                                <ListItemButton
                                                    key={index}
                                                    onClick={selectFilter(
                                                        header
                                                    )}
                                                >
                                                    <ListItemText>
                                                        {header.name}
                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            color="text.secondary"
                                                            sx={{
                                                                ml: 1,
                                                            }}
                                                        >
                                                            {toVerboseType(
                                                                header
                                                            )}
                                                        </Typography>
                                                    </ListItemText>
                                                </ListItemButton>
                                            )
                                        }
                                    )}
                                </List>
                            </Box>
                        </>
                    )}
                </Box>
            </Popover>
        </>
    )
}

export default FilterBarPopover
