import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

export interface FilterCardButtonProps {
    onApply?: React.MouseEventHandler<HTMLButtonElement>
    onCancel?: React.MouseEventHandler<HTMLButtonElement>
    onRemove?: React.MouseEventHandler<HTMLButtonElement>

    applyLabel?: string
    cancelLabel?: string
    removeLabel?: string

    applyDisabled?: boolean
}

export interface FilterCardContainerProps extends FilterCardButtonProps {
    name: string
    description: string
}

export function FilterCardContainer(
    props: React.PropsWithChildren<FilterCardContainerProps>
) {
    const { name, children, onApply, onCancel, onRemove, applyDisabled } = props
    let { applyLabel, cancelLabel, removeLabel } = props

    applyLabel = applyLabel || "Apply"
    cancelLabel = cancelLabel || "Cancel"
    removeLabel = removeLabel || "Remove"

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                minWidth: "400px",
                maxHeight: "480px",
                overflow: "hidden",
            }}
        >
            <Box sx={{ flex: "0 0 auto", m: 2 }}>
                <Typography variant="h6">{name}</Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flex: "0 1 auto",
                    minHeight: 0,
                    ml: 2,
                    mr: 2,
                }}
            >
                <Stack
                    component="form"
                    noValidate
                    spacing={2}
                    sx={{
                        mt: 1,
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    {children}
                </Stack>
            </Box>
            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                pt={5}
                sx={{
                    flex: "0 0 auto",
                    ml: 2,
                    mr: 2,
                    mb: 2,
                }}
            >
                {onCancel ? (
                    <Button variant="text" onClick={onCancel}>
                        {cancelLabel}
                    </Button>
                ) : null}
                {onRemove ? (
                    <Button variant="text" onClick={null}>
                        {removeLabel}
                    </Button>
                ) : null}
                <Button
                    variant="contained"
                    onClick={onApply}
                    disabled={applyDisabled}
                >
                    {applyLabel}
                </Button>
            </Stack>
        </Box>
    )
}
